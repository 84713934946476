import m from "mithril";

export const fetchSettings = (
  username: string
): Promise<{ data: { methods: string[]; opts: Record<string, string> } }> => {
  return m.request({
    method: "POST",
    url: "/auth/settings",
    body: { username },
  });
};
