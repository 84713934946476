import jwtDecode from "jwt-decode";
import m from "mithril";
import { AuthLoginResponse } from "~/types";
import { onLoginSuccess } from "~/utils/onLoginSuccess";

const googleLibInit = async (username: string, gcid: string) => {
  window.google.accounts.id.initialize({
    client_id: gcid,
    auto_select: true,
    callback: handleGoogleResponse,
    ux_mode: "popup",
  });
};

const postLogin = (email: string, token: string): Promise<AuthLoginResponse> => {
  return m.request({
    method: "POST",
    url: "/auth/login",
    body: { grant_type: "gsignin", username: email, password: token },
  });
};

export const handleGoogleResponse = (res: any) => {
  // eslint-disable-next-line no-console
  console.log(res);
  const response: any = jwtDecode(res.credential);

  // eslint-disable-next-line no-console
  console.log(response);
  postLogin(response.email, res.credential)
    .then((result) => onLoginSuccess(result))
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log("loginfailed", err);
    });
};

export const createGoogleButton = async (username: string, gcid: string, element: HTMLElement) => {
  await googleLibInit(username, gcid);

  window.google.accounts.id.renderButton(element, { type: "standard", width: 288 });
};

export const googleAuthPrompt = async (username = "", gcid: string, errorCallback: (error: any) => void) => {
  await googleLibInit(username, gcid);

  window.google.accounts.id.prompt(errorCallback);
};
