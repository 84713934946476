import { fetchSession } from "~/api/fetchSession";
import { onLoginSuccess } from "./onLoginSuccess";

export const checkAlreadyAuthenticated = async () => {
  try {
    const {
      data: { active },
    } = await fetchSession();

    if (active) {
      onLoginSuccess();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("failed", err);
  }
};
