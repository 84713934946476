import { clsx } from "clsx";
import m from "mithril";

interface LabelText {
  text?: string;
}

interface SizeClasses {
  size?: string;
}

interface TextFont {
  font?: string;
}

interface DelayClass {
  delay: "delay-1" | "delay-2" | "delay-3";
}

const ballsClasses: Record<DelayClass["delay"], string> = {
  "delay-1": "animate-balls",
  "delay-2": "animate-balls-100",
  "delay-3": "animate-balls-200",
};

const Dot: m.Component<SizeClasses & DelayClass> = {
  view: ({ attrs: { size, delay } }) => {
    size = size || clsx("w-2", "h-2", "mr-1.5");
    const ballsClass = ballsClasses[delay];

    return m(
      "div.flex.items-center",
      { "data-component": "Dot" },
      m("div", {
        class: clsx("border-slate-300", "bg-slate-300", "rounded-full", "border-1", size, ballsClass),
      })
    );
  },
};

const LoadingAnimation: m.Component<LabelText & SizeClasses & TextFont> = {
  view: ({ attrs: { text = "Loading", size, font } }) => {
    return m(
      "div.flex.items-center",
      { "data-component": "LoadingAnimation" },
      m(Dot, { size, delay: "delay-1" }),
      m(Dot, { size, delay: "delay-2" }),
      m(Dot, { size, delay: "delay-3" }),
      m("div.flex-1.text-xl.ml-2", m(font || "strong.font-bold", text))
    );
  },
};

const TinyLoadingAnimation: m.Component<LabelText> = {
  view: ({ attrs: { text } }) => {
    return m(
      "div.flex.items-center",
      { "data-component": "TinyLoadingAnimation" },
      m(LoadingAnimation, { font: "font-normal", size: clsx("mr-0.5", "w-2", "h-2"), text })
    );
  },
};

export const TinyLoadingAnimationCenter: m.Component<LabelText> = {
  view: ({ attrs: { text } }) => {
    return m(
      "div.flex.justify-center.p-4",
      { "data-component": "TinyLoadingAnimationCenter" },
      m(TinyLoadingAnimation, { text })
    );
  },
};
