import { clsx } from "clsx";
import m from "mithril";

const bubbleClasses: Record<number, object> = {
  1: { width: "11px", height: "11px", left: "10%", animateDuration: "18s" },
  2: { width: "12px", height: "12px", left: "20%", animateDuration: "18s", animationDelay: "4s" },
  3: { width: "14px", height: "14px", left: "35%", animateDuration: "20s", animationDelay: "2s" },
  4: { width: "15px", height: "15px", left: "50%", animateDuration: "17s" },
  5: { width: "16px", height: "16px", left: "55%", animateDuration: "16s", animationDelay: "9s" },
  6: { width: "15px", height: "15px", left: "65%", animateDuration: "18s", animationDelay: "6s" },
  7: { width: "14px", height: "14px", left: "75%", animateDuration: "17s", animationDelay: "2s" },
  8: { width: "13px", height: "13px", left: "80%", animateDuration: "16s", animationDelay: "8s" },
  9: { width: "14px", height: "14px", left: "70%", animateDuration: "19s" },
  10: { width: "15px", height: "15px", left: "85%", animateDuration: "15s", animationDelay: "3s" },
};

export const Bubbles: m.Component = {
  view() {
    return [...Array(10)].map((_, index) =>
      m("div", {
        class: clsx(
          "absolute",
          "-bottom-5",
          "bg-zinc-100",
          "pointer-events-none",
          "rounded-full",
          "opacity-50",
          "animate-floating"
        ),
        style: bubbleClasses[index + 1],
      })
    );
  },
};
