import m from "mithril";
import { AuthLoginResponse } from "~/types";

export const postLogin = (grant_type: string, username: string, pwd: string): Promise<AuthLoginResponse> => {
  return m.request({
    method: "POST",
    url: "/auth/login",
    body: { grant_type, username, password: pwd },
  });
};
