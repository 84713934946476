import m from "mithril";
import { PrimaryButton } from "./PrimaryButton";
import { EmailField } from "./TextField";

const areInputsValid = (username: string) => {
  return username !== "";
};

export const UsernameEntry: m.FactoryComponent<{
  username: string;
  onSetUsername: (username: string) => Promise<void>;
  setLoading: (value: boolean) => void;
  setHasError: (value: boolean) => void;
}> = ({ attrs: { username: initialUsername } }) => {
  let username = initialUsername;

  return {
    view({ attrs: { onSetUsername, setLoading, setHasError } }) {
      return m(
        "form",
        {
          class: "flex flex-col space-y-4",
          onsubmit: async (e: InputEvent) => {
            e.preventDefault();

            if (areInputsValid(username)) {
              await onSetUsername(username);
            }
          },
        },
        m(EmailField, {
          value: username,
          focus: true,
          label: "Email",
          placeholder: "Email address",
          autocomplete: "username",
          onValue: (value) => {
            username = value;
            setHasError(false);
          },
        }),
        // Hidden field to make life easier for password managers
        m("input", { id: "password", type: "text", autocomplete: "current-password", class: "sr-only", hidden: true }),
        m(
          "div.w-72",
          m(
            PrimaryButton,
            {
              disabled: !areInputsValid(username),
              onclick: async (e) => {
                e.preventDefault();

                try {
                  await onSetUsername(username);
                } catch (_) {
                  setLoading(false);
                  setHasError(true);
                }
              },
            },
            "Continue"
          )
        )
      );
    },
  };
};
