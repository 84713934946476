import m from "mithril";
import { checkAlreadyAuthenticated } from "~/utils/checkAlreadyAuthenticated";
import { initGlobalSettings } from "./globalSettings";
import { LoginPage } from "./LoginPage";

checkAlreadyAuthenticated();

initGlobalSettings();

const appElement = document.getElementById("app");

if (appElement) {
  m.route(appElement, "/", {
    "/": {
      render: (vnode) => {
        return m(LoginPage, vnode.attrs);
      },
    },
  });
}
