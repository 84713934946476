import { fetchGlobalSettings } from "~/api/fetchGlobalSettings";

let opts: Record<string, string> = {};

export const initGlobalSettings = async () => {
  const result = await fetchGlobalSettings();

  opts = result.data;
};

export const getGCID = () => {
  return opts.gcid;
};

export const isGoogleSigninEnabled = () => {
  return Boolean(window.google && opts.gcid);
};
