import { clsx } from "clsx";
import m from "mithril";

export const PrimaryButton: m.Component<{ disabled?: boolean; onclick?: (e: InputEvent) => void }> = {
  view({ attrs: { disabled, onclick }, children }) {
    return m(
      "button",
      {
        disabled,
        onclick,
        class: clsx(
          "w-full",
          "inline-flex",
          "items-center",
          "border",
          "border-transparent",
          "font-bold",
          "text-white",
          "focus:outline-none",
          "focus:ring-sky-500",
          "justify-around",
          "px-4",
          "py-2",
          "text-sm",
          "rounded-md",
          "shadow-sm",
          "focus:ring-2r",
          "focus:ring-offset-2r",
          disabled && ["bg-opacity-50", "bg-sky-800", "cursor-not-allowed"],
          !disabled && ["bg-sky-800", "hover:bg-sky-900"]
        ),
      },
      children
    );
  },
};
