import { AuthLoginResponse } from "~/types";

export const onLoginSuccess = (result?: AuthLoginResponse) => {
  // eslint-disable-next-line no-console
  console.log("login success, result: ", result);

  if (!result) {
    window.location.href = `${window.location.origin}/auth/onsuccess`;

    return;
  }

  if (result && result.appRedirectUrl) {
    window.location.href = result.appRedirectUrl;

    return;
  }

  // eslint-disable-next-line no-console
  console.error("invalid login result: ", result);
};
