import m from "mithril";
import { createGoogleButton } from "./GoogleAuth";

export const GoogleLoginButton: m.Component<{ username: string; gcid: string }> = {
  view({ attrs: { username, gcid } }) {
    return m(
      "div.flex.flex-col.items-center.h-full.max-h-32",
      m("div", {
        oncreate: async (vnode) => {
          createGoogleButton(username, gcid, vnode.dom as HTMLElement);
        },
      })
    );
  },
};
