import m from "mithril";
import { postLogin } from "~/api/postLogin";
import { onLoginSuccess } from "~/utils/onLoginSuccess";
import { LoginErrorLabel } from "./LoginErrorLabel";
import { PrimaryButton } from "./PrimaryButton";
import { PasswordField } from "./TextField";

let password = "";
// TODO: the error should be cleared when hitting the `X` button in the LoginPage
let error = false;

const doLogin = (username: string, password: string, setLoading: (v: boolean) => void) => {
  // eslint-disable-next-line no-console
  console.log("do login");
  setLoading(true);
  postLogin("password", username, password)
    .then((result) => {
      password = "";
      error = false;
      onLoginSuccess(result);
    })
    .catch((err) => {
      error = true;
      // eslint-disable-next-line no-console
      console.log("failed");
      // eslint-disable-next-line no-console
      console.log(err);
      setLoading(false);
    });
};

const areInputsValid = () => {
  return password !== "";
};

export const Password: m.Component<{ username: string; setLoading: (v: boolean) => void }> = {
  view({ attrs: { username, setLoading } }) {
    return m(
      "form",
      {
        class: "flex flex-col space-y-4 items-center",
        onsubmit: (e: InputEvent) => {
          e.preventDefault();

          if (areInputsValid()) {
            doLogin(username, password, setLoading);
          }
        },
      },
      // Hidden username field to make it easier for password managers etc
      m("input", {
        id: "username",
        type: "text",
        autocomplete: "username",
        class: "sr-only",
        value: username,
        hidden: true,
      }),
      m(PasswordField, {
        value: password,
        focus: true,
        label: "Password",
        placeholder: "Password",
        autocomplete: "current-password",
        onValue: (value) => {
          password = value;
        },
      }),
      m(
        "div.w-72",
        m(
          PrimaryButton,
          {
            disabled: !areInputsValid(),
            onclick: (e) => {
              e.preventDefault();
              doLogin(username, password, setLoading);
            },
          },
          "Sign in"
        )
      ),
      m(LoginErrorLabel, { error })
    );
  },
};
