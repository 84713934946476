declare global {
  interface Window {
    opr?: {
      addons?: any;
    };
    opera?: any;
    safari?: {
      pushNotification?: any;
    };
    chrome?: {
      webstore?: any;
      runtime?: any;
    };
    InstallTrigger?: any;
    StyleMedia?: any;
    google: any; // would be nice to find a typedef for googles new lib here
    gcid: string; // google client id
  }
  interface Document {
    documentMode?: any;
  }
}
// Avoid checking User agent string since it's easily changed/spoofed

// Opera 8.0+
const isOpera =
  (!!window.opr && window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.includes(" OPR/");

// Firefox 1.0+
const isFirefox = typeof window.InstallTrigger !== "undefined";

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
  /constructor/i.test(window.HTMLElement as unknown as string) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || (typeof window.safari !== "undefined" && window.safari.pushNotification));

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Edge (based on chromium) detection
const isEdgeChromium = isChrome && navigator.userAgent.includes("Edg");

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

const localStorageGetItem = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (_) {
    // eslint-disable-next-line no-console
    console.warn("DF doesn't have permissions to access localstorage");

    return null;
  }
};

const localStorageSetItem = (key: string, value: string): void => {
  try {
    localStorage.setItem(key, value);
  } catch (_) {
    // eslint-disable-next-line no-console
    console.warn("DF doesn't have permissions to access localstorage");
  }
};

export const Browser = {
  isOpera,
  isFirefox,
  isSafari,
  isIE,
  isEdge,
  isChrome,
  isEdgeChromium,
  isBlink,
  localStorageGetItem,
  localStorageSetItem,
};
