import m from "mithril";

export const Copyright: m.Component<void> = {
  view: () => {
    return m(
      "div.text-center.text-white",
      `© Copyright ${new Date().getFullYear()} Doublefin from Visdom Technologies Inc.`
    );
  },
};
