import m from "mithril";
import logoTextSvg from "../assets/logo-text.svg";

const SvgImages: m.Component<{ svg: string } & m.Attributes> = {
  view(vnode) {
    const { svg, ...attrs } = vnode.attrs;

    return m("div", attrs, m.trust(svg));
  },
};

function of(resource: string): m.Component<m.Attributes> {
  return {
    view: (vnode) => {
      return m(SvgImages, { svg: resource, ...vnode.attrs });
    },
  };
}

export const doublefinLogo = of(logoTextSvg);
