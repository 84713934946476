import m from "mithril";
import { PrimaryButton } from "./PrimaryButton";

interface Attrs {
  state: "username" | "auth";
  reset: () => void;
}

export const OptionalBackButton: m.Component<Attrs> = {
  view: ({ attrs: { state, reset } }) => {
    if (state === "username") {
      return;
    }

    return m("div.w-72", m(PrimaryButton, { onclick: () => reset() }, "Back"));
  },
};
