import m from "mithril";
import { GoogleAuthOneTapOrPopup } from "~/gsignin/GoogleAuthOneTapOrPopup";
import { Password } from "./Password";
import { Separator } from "./Separator";

type SetLoading = (value: boolean) => void;
type Opts = Record<string, string>;

const authMethodOrder = ["USERPASSWORD", "GSIGNIN", "SAML", "WORKOS"];

const renderAuthMethod = (method: string, username: string, setLoading: SetLoading, opts: Opts) => {
  switch (method) {
    case "USERPASSWORD":
      return m(Password, { username, setLoading });
    case "GSIGNIN":
      return m(GoogleAuthOneTapOrPopup, { username, gcid: opts.gcid });
    case "SAML":
      return window.location.replace(opts.samlLoginUrl);
    case "WORKOS":
      return window.location.replace(opts.workOsLoginUrl);
  }
};

const renderAuthMethodComponents = (methods: string[], username: string, setLoading: SetLoading, opts: Opts) => {
  return authMethodOrder
    .filter((method) => methods.includes(method))
    .map((method, index) => {
      if (index > 0) {
        return [m(Separator), renderAuthMethod(method, username, setLoading, opts)];
      } else {
        return renderAuthMethod(method, username, setLoading, opts);
      }
    });
};

interface Attrs {
  methods: string[];
  username: string;
  setLoading: SetLoading;
  opts: Opts;
}

export const AuthMethods: m.Component<Attrs> = {
  view({ attrs: { methods, username, setLoading, opts } }) {
    return m(
      "div.space-y-3",
      m("div", username),
      m("div", renderAuthMethodComponents(methods, username, setLoading, opts))
    );
  },
};
