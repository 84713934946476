import m from "mithril";
import { createGoogleButton, googleAuthPrompt } from "./GoogleAuth";

const userCanceledOneTapFlow = (notification: any, username: string, gcid: string, element: HTMLElement) => {
  // make sure there is a fresh button, might depend on auto_select/user cancelation reason/timeout suppresion/browser
  createGoogleButton(username, gcid, element);
};

export const GoogleAuthOneTapOrPopup: m.Component<{ username: string; gcid: string }> = {
  view({ attrs: { username, gcid } }) {
    return m(
      "div.flex.flex-col.justify-center.h-full.max-h-32",
      m("div", {
        oncreate: async (vnode) => {
          await googleAuthPrompt(username, gcid, (notification: any) =>
            userCanceledOneTapFlow(notification, username, gcid, vnode.dom as HTMLElement)
          );
        },
      })
    );
  },
};
